import React from 'react'
import { TreeSelect } from 'antd'
import 'antd/es/tree-select/style/css'

export function InstitutionSelect ({
  institutions,
  onChange,
  value,
  placeholder,
  emptyText,
  disabled
}) {
  if (emptyText) institutions.unshift({ value: 0, title: emptyText })

  return (
    <TreeSelect
      disabled={disabled}
      showSearch
      style={{ width: '100%' }}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={placeholder}
      treeNodeFilterProp='title'
      treeData={institutions}
      value={value}
      onChange={onChange}
    />
  )
}
