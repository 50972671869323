import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import GET from '../dao/get'

const useInstitutions = (defaultValue, options = {}) => {
  const [institutions, setInstitutions] = useState(defaultValue)

  useEffect(() => {
    const queryParams = `force_id=${options.force_id}`
    GET('institutions', queryParams).then(res => {
      if (res.status >= 400) {
        toast.error(res.body.message)
      } else if (res.body !== null) {
        setInstitutions(res.body)
      }
    })
  }, [])

  return [institutions, setInstitutions]
}

export default useInstitutions
